import { ISelectOption } from '../../types/interfaces'
import { IProductFilter } from '../../queries/products/types'
import { PRODUCT_STATUSES } from '../inventory/constants'

export const GET_CUSTOM_ASSORTMENTS = 'GET_CUSTOM_ASSORTMENTS'
export const GET_ASSORTMENT_COMPONENTS = 'GET_ASSORTMENT_COMPONENTS'
export const SEARCH_PRODUCTS_FOR_ASSORTMENTS = 'SEARCH_PRODUCTS_FOR_ASSORTMENTS'
export const GET_ASSORTMENT_DETAILS = 'GET_ASSORTMENT_DETAILS'
export const GET_ASSORTMENT_GHOST_ORDER = 'GET_ASSORTMENT_GHOST_ORDER'
export const GET_DEAL_WITH_PRODUCTS = 'GET_DEAL_WITH_PRODUCTS'
export const GET_ACTIVE_PRICE_LEVELS = 'GET_ACTIVE_PRICE_LEVELS'
export const GET_PRODUCT = 'GET_PRODUCT'
export const GET_PRODUCT_COLORS = 'GET_PRODUCT_COLORS'
export const GET_PRODUCT_EFFECTS = 'GET_PRODUCT_EFFECTS'
export const GET_PRODUCT_FIRING_PATTERNS = 'GET_PRODUCT_FIRING_PATTERNS'
export const GET_PRODUCT_DEBRIS_PROFILES = 'GET_PRODUCT_DEBRIS_PROFILES'
export const GET_PRODUCT_BRANDS = 'GET_PRODUCT_BRANDS'
export const GET_PRODUCT_SUPPLIERS = 'GET_PRODUCT_SUPPLIERS'
export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES'
export const GET_PRODUCTS_LIST = 'GET_PRODUCTS_LIST'
export const SET_PRODUCT_FILTERS = 'SET_PRODUCT_FILTERS'
export const SET_CATEGORY_FILTER = 'SET_CATEGORY_FILTER'
export const SET_BRAND_FILTER = 'SET_BRAND_FILTER'
export const SET_SITEMAP_FILTER = 'SET_SITEMAP_FILTER'

export const defaultProductFilters: IProductFilter = {
  products_status: PRODUCT_STATUSES.ACTIVE,
  search_text: '',
  sort_by: 'products_model',
  categories_id: '',
  suppliers_id: '',
  brand_id: '',
  duration: '',
  is_on_wholesale_site: false,
  is_on_proline_site: false,
}

export const unNumberOptions: Array<ISelectOption> = [
  { value: 'UN0105', label: 'UN0105', id: 'UN0105' },
  { value: 'UN0336', label: 'UN0336', id: 'UN0336' },
  { value: 'UN0337', label: 'UN0337', id: 'UN0337' },
  { value: 'UN0431', label: 'UN0431', id: 'UN0431' },
  { value: 'UN0432', label: 'UN0432', id: 'UN0432' },
  { value: 'UN0454', label: 'UN0454', id: 'UN0454' },
  { value: 'Unregulated', label: 'Unregulated', id: 'Unregulated' },
]

export const skuTypeOptions: Array<ISelectOption> = [
  { value: 'Case', label: 'Case', id: 'Case' },
  { value: 'Pack', label: 'Pack', id: 'Pack' },
  { value: 'Piece', label: 'Piece', id: 'Piece' },
  { value: 'Assortment', label: 'Assortment', id: 'Assortment' },
  { value: 'Promotional', label: 'Promotional', id: 'Promotional' },
]

export const ignitionTypeOptions: Array<ISelectOption> = [
  { value: '', label: 'None', id: 'None' },
  { value: 't-lock', label: 't-lock', id: 't-lock' },
  { value: 'e-port', label: 'e-port', id: 'e-port' },
  { value: 'iShot', label: 'iShot', id: 'iShot' },
  { value: 'prematched', label: 'prematched', id: 'prematched' },
]

export const statusOptions: Array<ISelectOption> = [
  { value: '0', label: 'Inactive', id: '0' },
  { value: '1', label: 'Active', id: '1' },
  { value: '2', label: 'Archived', id: '2' },
]

export const f3dTypeOptions: Array<ISelectOption> = [
  { value: '', label: 'None', id: 'None' },
  { value: 'Cakes', label: 'Cakes', id: 'Cakes' },
  { value: 'Candles', label: 'Candles', id: 'Candles' },
  { value: 'Comets', label: 'Comets', id: 'Comets' },
  { value: 'Flame', label: 'Flame', id: 'Flame' },
  { value: 'Ground', label: 'Ground', id: 'Ground' },
  { value: 'Light', label: 'Light', id: 'Light' },
  { value: 'Mines', label: 'Mines', id: 'Mines' },
  { value: 'Not An Effect', label: 'Not An Effect', id: 'Not An Effect' },
  { value: 'Other', label: 'Other', id: 'Other' },
  { value: 'Rack', label: 'Rack', id: 'Rack' },
  { value: 'Rocket', label: 'Rocket', id: 'Rocket' },
  { value: 'Sfx', label: 'Sfx', id: 'Sfx' },
  { value: 'Shells', label: 'Shells', id: 'Shells' },
  { value: 'Single Shot', label: 'Single Shot', id: 'Single Shot' },
]

export const f3dSubtypeOptions: Array<ISelectOption> = [
  { value: '', label: 'None', id: 'None' },
  { value: '200g Cakes', label: '200g Cakes', id: '200g Cakes' },
  {
    value: '200g Finale Boxes',
    label: '200g Finale Boxes',
    id: '200g Finale Boxes',
  },
  { value: '500g Cakes', label: '500g Cakes', id: '500g Cakes' },
  {
    value: '500g Finale Boxes',
    label: '500g Finale Boxes',
    id: '500g Finale Boxes',
  },
  {
    value: 'Comets (No Debris)',
    label: 'Comets (No Debris)',
    id: 'Comets (No Debris)',
  },
  {
    value: 'Comets (Reloadable)',
    label: 'Comets (Reloadable)',
    id: 'Comets (Reloadable)',
  },
  { value: 'Comets', label: 'Comets', id: 'Comets' },
  { value: 'Daytime Cakes', label: 'Daytime Cakes', id: 'Daytime Cakes' },
  { value: 'FanSlice', label: 'FanSlice', id: 'FanSlice' },
  { value: 'FanSweep', label: 'FanSweep', id: 'FanSweep' },
  { value: 'Flame Pots', label: 'Flame Pots', id: 'Flame Pots' },
  { value: 'Fountains', label: 'Fountains', id: 'Fountains' },
  { value: 'Gerbs', label: 'Gerbs', id: 'Gerbs' },
  { value: 'GhostSweep', label: 'GhostSweep', id: 'GhostSweep' },
  {
    value: 'Mines (No Debris)',
    label: 'Mines (No Debris)',
    id: 'Mines (No Debris)',
  },
  { value: 'Mines', label: 'Mines', id: 'Mines' },
  { value: 'Roman Candles', label: 'Roman Candles', id: 'Roman Candles' },
  {
    value: 'Shells (60mm Pre-Loaded)',
    label: 'Shells (60mm Pre-Loaded)',
    id: 'Shells (60mm Pre-Loaded)',
  },
  {
    value: 'Shells (Reloadable)',
    label: 'Shells (Reloadable)',
    id: 'Shells (Reloadable)',
  },
  {
    value: 'Single Shot Bombards (40mm)',
    label: 'Single Shot Bombards (40mm)',
    id: 'Single Shot Bombards (40mm)',
  },
  { value: 'Special Effects', label: 'Special Effects', id: 'Special Effects' },
  { value: 'Strobes', label: 'Strobes', id: 'Strobes' },
  { value: 'SuperSlice', label: 'SuperSlice', id: 'SuperSlice' },
  { value: 'SuperSweep', label: 'SuperSweep', id: 'SuperSweep' },
]

export const cobraTypeOptions: Array<ISelectOption> = [
  { value: '', label: 'None', id: 'None' },
  {
    value: 'Artillery Shells',
    label: 'Artillery Shells',
    id: 'Artillery Shells',
  },
  { value: 'Burst', label: 'Burst', id: 'Burst' },
  { value: 'Cake Slice', label: 'Cake Slice', id: 'Cake Slice' },
  { value: 'Comets', label: 'Comets', id: 'Comets' },
  { value: 'Firecrackers', label: 'Firecrackers', id: 'Firecrackers' },
  { value: 'Flame Pot', label: 'Flame Pot', id: 'Flame Pot' },
  { value: 'Fountain', label: 'Fountain', id: 'Fountain' },
  { value: 'Gerb', label: 'Gerb', id: 'Gerb' },
  { value: 'Girandola', label: 'Girandola', id: 'Girandola' },
  { value: 'Lantern', label: 'Lantern', id: 'Lantern' },
  { value: 'Line Rocket', label: 'Line Rocket', id: 'Line Rocket' },
  { value: 'Mine', label: 'Mine', id: 'Mine' },
  { value: 'Missiles', label: 'Missiles', id: 'Missiles' },
  {
    value: 'Multi-shot Cake 200g',
    label: 'Multi-shot Cake 200g',
    id: 'Multi-shot Cake 200g',
  },
  {
    value: 'Multi-shot Cake 350g',
    label: 'Multi-shot Cake 350g',
    id: 'Multi-shot Cake 350g',
  },
  {
    value: 'Multi-shot Cake 500g',
    label: 'Multi-shot Cake 500g',
    id: 'Multi-shot Cake 500g',
  },
  { value: 'Novelties', label: 'Novelties', id: 'Novelties' },
  { value: 'Parachutes', label: 'Parachutes', id: 'Parachutes' },
  { value: 'Punk', label: 'Punk', id: 'Punk' },
  { value: 'Rockets', label: 'Rockets', id: 'Rockets' },
  { value: 'Roman Candle', label: 'Roman Candle', id: 'Roman Candle' },
  {
    value: 'Single Shot Tubes',
    label: 'Single Shot Tubes',
    id: 'Single Shot Tubes',
  },
  { value: 'Smoke Bombs', label: 'Smoke Bombs', id: 'Smoke Bombs' },
  { value: 'Special Effects', label: 'Special Effects', id: 'Special Effects' },
  { value: 'Strobe Pot', label: 'Strobe Pot', id: 'Strobe Pot' },
]
